"use client";

import { useEffect } from "react";

const Error = ({ error }: { error: Error & { digest?: string } }) => {
  useEffect(() => {
    // 收集错误日志，发送给服务端
    console.error("error", error);
  }, [error]);

  return (
    <div className="page-container">
      <h2 className="h-10 text-center leading-10">页面访问错误！</h2>
    </div>
  );
};

export default Error;
